import { Description } from "@/components/ui/description";
import LoadingSpinner from "@/components/ui/loading-spinner";
import { useTranslation } from "next-i18next";
import React, { PropsWithChildren } from "react";

type LoadingContextData = {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoadingContext = React.createContext({} as LoadingContextData);

export const useLoading = () => {
  const context = React.useContext(LoadingContext);

  if (!context) {
    throw new Error("useLoading should be used within <LoadingProvider>");
  }

  return context;
};

export const LoadingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { t } = useTranslation();

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      {isLoading && (
        <div
          className={
            "fixed left-0 top-0 z-50 flex h-screen w-screen flex-col items-center justify-center gap-2 bg-white/90 px-3"
          }
        >
          <div role="status">
            <LoadingSpinner />
            <span className="sr-only">{t("loading.loading")}</span>
          </div>
          <Description className="text-center text-base">
            {t("loading.submit")}
          </Description>
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};
