import { cn } from "@/lib/utils";
import React from "react";
import { VariantProps, tv } from "tailwind-variants";

const descriptionVariants = tv({
  base: "font-book text-muted-foreground",
  variants: {
    variant: {
      input: "text-xs",
      section: "text-sm",
    },
  },
  defaultVariants: {
    variant: "section",
  },
});

export const Description = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> &
    VariantProps<typeof descriptionVariants>
>(({ className, ...props }, ref) => {
  return (
    <p
      ref={ref}
      className={cn(descriptionVariants(props), className)}
      {...props}
    />
  );
});

Description.displayName = "Description";
