// log the pageview with their URL
export const pageview = (url: string) => {
  try {
    (window as any).gtag(
      "config",
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
      {
        page_path: url,
      },
    );
  } catch (error) {}
};

// log specific events happening.
export const event = ({ action, params }: { action: string; params: any }) => {
  try {
    (window as any).gtag("event", action, params);
  } catch (error) {}
};
