import { LoadingProvider } from "@/contexts/loading";
import { getSpanishArticle } from "@/helpers/getSpanishArticle";
import { getVariationOfAOrAn } from "@/helpers/getVariationOfOrAn";
import "@/styles/globals.css";
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import localFont from "next/font/local";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import i18nConfig from "../../next-i18next.config";
import * as ga from "../lib/ga";
// import { datadogRum } from "@datadog/browser-rum";
// import { datadogLogs } from "@datadog/browser-logs";

// datadogRum.init({
//   applicationId: "33b209d8-3171-4a86-b939-759ce66faaa7",
//   clientToken: "pub30cde32588a35af7477e7ae294b3c913",
//   site: "datadoghq.com",
//   service: "nona---labor-and-delivery-registration-form",
//   env: "prod",
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 100,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: "mask-user-input",
// });
//
// datadogLogs.init({
//   clientToken: "pub30cde32588a35af7477e7ae294b3c913",
//   site: "datadoghq.com",
//   service: "nona---labor-and-delivery-registration-form",
//   forwardErrorsToLogs: true,
//   sessionSampleRate: 100,
//   env: "prod",
// });

const circularXX = localFont({
  src: [
    {
      path: "../assets/fonts/circular-xx/CircularXX-Black.otf",
      style: "normal",
      weight: "900",
    },
    {
      path: "../assets/fonts/circular-xx/CircularXX-Bold.otf",
      style: "normal",
      weight: "700",
    },
    {
      path: "../assets/fonts/circular-xx/CircularXX-Medium.otf",
      style: "normal",
      weight: "500",
    },
    {
      path: "../assets/fonts/circular-xx/CircularXX-Book.otf",
      style: "normal",
      weight: "450",
    },
    {
      path: "../assets/fonts/circular-xx/CircularXX-Regular.otf",
      style: "400",
    },
    {
      path: "../assets/fonts/circular-xx/CircularXX-Light.otf",
      style: "normal",
      weight: "300",
    },
    {
      path: "../assets/fonts/circular-xx/CircularXX-Thin.otf",
      style: "normal",
      weight: "100",
    },
  ],
  variable: "--font-circular-xx",
});

function App({
  Component,
  pageProps: { dehydratedState, ...pageProps },
}: AppProps) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
          },
        },
      }),
  );

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        <main className={circularXX.className}>
          <LoadingProvider>
            <Component {...pageProps} />
          </LoadingProvider>
        </main>
      </HydrationBoundary>
    </QueryClientProvider>
  );
}

export default appWithTranslation(App, {
  ...i18nConfig,
  interpolation: {
    format: function (value, format, lng) {
      if (format === "en-handle-an")
        return !lng || lng === "en" ? getVariationOfAOrAn(value, false) : "";
      if (format === "en-handle-an-capitalized")
        return !lng || lng === "en" ? getVariationOfAOrAn(value, true) : "";
      if (format === "es-handle-an")
        return !lng || lng === "es" ? getSpanishArticle(value, false) : "";
      if (format === "es-handle-an-capitalized")
        return !lng || lng === "es" ? getSpanishArticle(value, true) : "";
      return value;
    },
  },
});
