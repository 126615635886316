export const getSpanishArticle = function (value: string, capitalize: boolean) {
  const letters = ["a", "e", "i", "o", "u", "h"];
  let firstLetter = value.substring(0, 1);
  let correctWordForm = "";
  if (
    letters.find(function (l) {
      return firstLetter.toLocaleLowerCase() === l;
    })
  ) {
    correctWordForm = capitalize ? "Una" : "una";
  } else {
    correctWordForm = capitalize ? "Un" : "un";
  }

  return correctWordForm;
};
